<template>
  <div>
    <v-card title="Importar Arquivo" flat>
      <v-card-text>
        <div class="mb-3">
          <label for="columnSelect" class="form-label fw-bold">
            Selecione a coluna que contém o código do produto
          </label>
          <select
            id="columnSelect"
            class="form-select"
            v-model="selectedColumn"
          >
            <option hidden value="">Selecione</option>
            <option
              v-for="(column, key) in arquivoInfo.columns"
              :key="key"
              :value="key"
            >
              {{ column.label }}
            </option>
          </select>
        </div>

        <div v-if="arquivoInfo.rows.length" id="preview-arquivo">
          <p class="fw-bold">Prévia do arquivo:</p>
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th
                    v-for="(column, key) in arquivoInfo.columns"
                    :key="key"
                  >
                    {{ column.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in arquivoInfo.rows.slice(0, 5)"
                  :key="index"
                >
                  <td
                    v-for="(column, key) in arquivoInfo.columns"
                    :key="key"
                  >
                    {{ row[key] }}
                  </td>
                </tr>
                <tr v-if="arquivoInfo.rows.length > 5">
                  <td
                    :colspan="Object.keys(arquivoInfo.columns).length"
                    class="text-center"
                  >
                    ...
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    arquivoInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedColumn: "",
    };
  },
  watch: {
    selectedColumn(newValue) {
      this.$emit("column-selected", newValue);
    },
  },
};
</script>

<style scoped>
    .table {
			font-size: 0.875rem;
    }
    .table th,
    .table td {
			text-align: center;
			vertical-align: middle;
    }
    .form-select {
			display: block;
			background-color: #ececec;
			padding: 8px;
			padding-inline: 1rem;
			border: #ccc 1px solid;
			border-radius: 4px;
    }
</style>
