<template>
  <modal
      name="campoAdicionalOpcoes"
      height="auto"
      width="600"
      :scrollable="true"
      :clickToClose="false"
  >
    <CloseModal :close="closeModal" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CCard v-if="campoEditado.campo_adicional_opcoes.length === 0">
          <CCardBody>
            <div class="row">
              <div class="col text-center">Sem opções para listar 😕</div>
            </div>
          </CCardBody>
        </CCard>
        <CCard v-for="(opcao, indice) in campoEditado.campo_adicional_opcoes" :key="indice">
          <CCardBody>
            <div class="row">
              <div class="col-4">
                <label>Chave</label>
                <input
                    type="text"
                    class="form-control"
                    maxlength="30"
                    :value="opcao.chave"
                    @input="(event) => onChangeInput(event.target.value, indice, opcao, 'chave')"
                />
              </div>
              <div class="col">
                <label>Valor</label>
                <input
                    type="text"
                    class="form-control"
                    maxlength="30"
                    :value="opcao.valor"
                    @input="(event) => onChangeInput(event.target.value, indice, opcao, 'valor')"
                />
              </div>
              <div class="col-1">
                <button
                    type="button"
                    name="button"
                    class="btn-sm btn-danger"
                    @click="onRemoveOpcao(indice)"
                >
                  <fa-icon icon="trash-alt"></fa-icon>
                </button>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div class="modal-footer">
        <button class="button-success button w-25 float-right" @click="onAdd">
          Incluir opção
        </button>
        <button
            class="button-success button w-25 float-right"
            v-on:click="onSubmit"
        >
          Salvar
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import _remove from "lodash/remove";

const novaOpcao = (campoadicional_id) => ({
  valor: '',
  chave: '',
  campoadicional_id: campoadicional_id,
});

export default {
  name: "CampoAdicionalOpcoes",
  props: ["campoEditado"],
  components: {
    CloseModal,
  },
  methods: {
    closeModal() {
      _remove(this.campoEditado.campo_adicional_opcoes, (opcao) => opcao.valor.trim().length === 0);
      this.$modal.hide("campoAdicionalOpcoes");
    },
    onAdd() {
      this.campoEditado.campo_adicional_opcoes.push(novaOpcao(this.campoEditado.id));
    },
    onChangeInput(text, indice, opcao, ref) {
      this.campoEditado.campo_adicional_opcoes[indice][ref]              = text.replace(/ /g, '_');
      this.campoEditado.campo_adicional_opcoes[indice].id                = opcao.id;
      this.campoEditado.campo_adicional_opcoes[indice].campoadicional_id = opcao.campoadicional_id;
    },
    onRemoveOpcao(indice) {
      this.campoEditado.campo_adicional_opcoes.splice(indice, 1);
    },
    onSubmit() {
      // foi feito isso para dar um "refresh" no array
      this.campoEditado.campo_adicional_opcoes.push(novaOpcao(this.campoEditado.id));
      this.campoEditado.campo_adicional_opcoes.splice(
          this.campoEditado.campo_adicional_opcoes.length -1,
          1
      );

      this.closeModal();
    },
  },
  beforeMount() {
    if (!this.campoEditado.campo_adicional_opcoes) {
      this.campoEditado.campo_adicional_opcoes = [];
    }
  },
};
</script>

<style scoped></style>
