<template>
  <div class="container">
    <ModalImportar
      titulo="Importar Planilha"
      :close="fecharModal"
       @produtos-importados="emitirProdutos"
    />
    <div class="mix-header">
      <div class="usa-restricao">
        <input
          id="usaRestricao"
          type="checkbox"
          :checked="usaRestricaoCompra === 1"
          @change="toggleCheckbox"
        />
        <label for="usaRestricao">Usar Restrições de Compra</label>
      </div>
      <div class="action-buttons">
        <CButton
          color="danger"
          :disabled="!usaRestricaoCompra"
          @click="limparProdutos"
        >
          Limpar
        </CButton>
        <CButton
          :disabled="!usaRestricaoCompra"
          color="primary"
          @click="abrirModal"
        >
          Importar
        </CButton>
      </div>
    </div>
    <div class="d-flex justify-content-between gap-2 mb-3 flex-wrap">
      <input
        type="text"
        class="form-control"
        placeholder="Buscar por ID ou Descrição"
        v-model="termoBusca"
        :disabled="!usaRestricaoCompra"
        style="max-width: 320px"
        @input="debounceBuscarProdutos"
      />
      <CButton color="primary" @click="exibirRestritos()">Exibir Restritos</CButton>
    </div>

    <div class="table table-sm table-hover w-100">
      <div class="table-body-scroll">
        <div v-if="!listarProdutos.length && termoBusca" class="text-center overflow-hidden m-4">
          <span>Nenhum produto encontrado</span>
        </div>
        <div v-if="listarProdutos.length && termoBusca">
          <div v-for="produto in listarProdutos" :key="produto.id">
            <CCard class="card">
              <CCardTitle class="text">
                <p>COD</p>
                <label>{{produto.idExibicao}}</label>
              </CCardTitle>
              <img class="image" :src="cdnUrl(produto.idExibicao)" :alt="produto.descComp"/>
              <CCardBody class="cardBody">
                <CCardText class="text">
                  <p>Descrição</p>
                  <label>  {{ produto.descComp }}</label>
                </CCardText>
                <label class="c-switch c-switch-sm c-switch-3d c-switch-success">
                  <input 
                    type="checkbox"
                    class="c-switch-input"
                    :checked="produtosAdicionados.some(item => item.value == produto.id)"
                    @change="toggleProduto(produto)"
                  />
                  <span class="c-switch-slider"></span>
                </label>
              </CCardBody>
            </CCard>
          </div>
        </div>
        <div v-else-if="produtosAdicionados.length && !termoBusca">
          <div v-for="produto in produtosAdicionados" :key="produto.value" class="">
           <CCard class="card">
              <CCardTitle class="text">
                <p>COD</p>
                <label>{{produto.value}}</label>
              </CCardTitle>
              <img class="image" :src="cdnUrl(produto.value)" :alt="produto.label"/>
              <CCardBody class="cardBody">
                <CCardText class="text">
                  <p>Descrição</p>
                  <label>  {{ produto.label }}</label>
                </CCardText>
                <CButton class="trash-button" color="danger" @click="removerProduto(produto.value)">
                  <fa-icon icon="trash-alt" />
                </CButton>
              </CCardBody>
            </CCard>
          </div>
        </div>
        <div v-else-if="produtosAdicionados.length === 0 && !termoBusca" class="text-center overflow-hidden m-4">
          <span>Nenhum produto adicionado</span>
        </div>
      </div>
    </div>

    <div v-if="termoBusca" class="d-flex justify-content-center p-2">
      <pagination
        :data="paginator"
        :limit="2"
        @pagination-change-page="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import { get } from '../../../helpers/apiRequest';
import { cdnUrlProduto } from '../../../helpers/url';
import { makeHash, removeDuplicados } from '../../../helpers/utils';
import ModalImportar from './ModalImportar.vue';

export default {
  name: "MixProdutos",
  components: {
    ModalImportar
  },
  props: {
    produtosRestritos: {
      type: Array,
      required: false,
      default: () => [],
    },
    usaRestricaoCompra: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      listarProdutos: [], 
      paginator: {},
      termoBusca: "",
      produtosAdicionados: [],
      currentPage: 1,
      debounce: null,
      currentHash: makeHash(),
    };
  },
  methods: {
    abrirModal() {
      this.$modal.show("modalImportar");
    },
    fecharModal() {
      this.$modal.hide("modalImportar");
    },
    removerProduto(value) {
      this.produtosAdicionados = this.produtosAdicionados.filter(
        (produto) => produto.value !== value
      );

      this.$emit(
        "atualizar-produtos-restritos",
        this.produtosAdicionados
      );
    },
    toggleProduto(produto) {
      const { id } = produto;
      
      // Copia o array local para manipulação
      let produtosAtualizados = [...this.produtosAdicionados];

      const index = produtosAtualizados.findIndex((item) => item.id === id);

      if (index !== -1) {
        // Remove o item se já existir
        produtosAtualizados.splice(index, 1);
      } else {
        // Adiciona o item se não existir
        produtosAtualizados.push({
          id: produto.id,
          value: produto.id,
          label: produto.descComp,
        });
      }

      // Atualiza o estado local
      this.produtosAdicionados = produtosAtualizados;

      // Emite a lista atualizada para o componente pai
      this.$emit(
        "atualizar-produtos-restritos",
        produtosAtualizados
      );
    },
    changePage(page) {
      if (page >= 1 && page <= this.paginator.last_page) {
        this.fetchMixProduto(page);
      }
    },
    cdnUrl(id) {
      return cdnUrlProduto(id, this.currentHash);
    },
    toggleCheckbox(event) {
      const novoValor = event.target.checked ? 1 : 0;
      this.$emit("usa-restricao", novoValor);
    },
    async fetchMixProduto(page = 1) {
      this.currentHash = makeHash();

      const filtro = isNaN(this.termoBusca)
        ? `filtros[termo]=${this.termoBusca}`
        : `filtros[produto_id]=${this.termoBusca}`;

      try {
        const response = await get(`/admin/produtos?page=${page}&${filtro}`);
        this.listarProdutos = response.itens || [];
        this.paginator = response.paginator || {
          total: 0,
          per_page: 12,
          current_page: 1,
          last_page: 1,
        };
      } catch (err) {
        console.error("Erro ao buscar produtos:", err);
        this.listarProdutos = [];
        this.paginator = {
          total: 0,
          per_page: 12,
          current_page: 1,
          last_page: 1,
        };
      }
    },
    debounceBuscarProdutos() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchMixProduto();
      }, 500);
    },
    limparProdutos() {
      this.produtosAdicionados = [];
      this.$emit("atualizar-produtos-restritos", []);
    },
    abrirModalImportar() {
      this.$emit("abrir-modal-importar");
    },
    exibirRestritos() {
      this.termoBusca = "";
    },
    handlePageChange(page) {
      this.page = page;
      this.fetchMixProduto(this.page);
    },
    emitirProdutos(produtos) {
      const produtosAdicionados = this.produtosAdicionados = [...this.produtosAdicionados, ...produtos];
      const removeProdutosDuplicados = removeDuplicados(produtosAdicionados, 'value');
      
      this.produtosAdicionados = removeProdutosDuplicados;
      this.$emit("produtos-importados", removeProdutosDuplicados);
    },
  },
  mounted() {
    if(this.produtosRestritos.length > 0){
      this.produtosAdicionados = this.produtosRestritos
    }
  },
};
</script>

<style scoped>
  .container{
    padding: 1rem;
    /* height: 55vh; */
  }
  .mix-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .table-body-scroll{
    max-height: 40vh;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .search-input {
    width: 200px;
  }

  .table-container {
    max-height: 320px;
    overflow-y: auto;
    /* border: 1px solid #ddd; */
  }
  .table {
    width: 100%;
    border: none;
    /* height: 50vh; */
    /* border-collapse: collapse; */
  }
  .table th,
  .table td {
    padding: 8px;
  }
  .button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .button-success {
    background-color: #28a745;
    color: white;
    border: none;
  }
  .button-error {
    background-color: #dc3545;
    color: white;
    border: none;
  }
   .usa-restricao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .usa-restricao label {
    vertical-align: middle;
    margin: 0;
  }

  .action-buttons{
    display: flex;
    gap: 1rem;
  }
  .card{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 0.5rem;
    padding-inline: 2rem;
  }

  .text p {
    font-weight: bold;
  }
  .cardBody {
    display: flex;
    justify-content: space-between;
  }
  .image {
    width: 120px;
    height: 120px;
  }

  .trash-button {
    width: 38px;
    height: 38px;
    align-self: center;
  }
</style>
