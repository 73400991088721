<template>
  <v-stepper :value="currentStep">
    <!-- Header com os steps -->
    <v-stepper-header>
      <v-stepper-step
        v-for="(step, index) in steps"
        :key="index"
        :step="index + 1"
      >
        {{ step }}
      </v-stepper-step>
    </v-stepper-header>

    <!-- Conteúdo dos steps -->
    <v-stepper-items>
      <v-stepper-content
        v-for="(step, index) in steps"
        :key="index"
        :step="index + 1"
      >
        <slot :name="`step.${index + 1}`"></slot>

        <div class="step-actions">
          <CButton v-if="index > 0"
             text
             @click="voltarEtapa"
             color="danger"
             class="button-largura"
            >
              Voltar
          </CButton>
          <CButton
            v-if="index === 1"
            color="primary"
            @click="avancarEtapa"
            class="button-largura"
            :disabled="!isColunaSelecionavel"
          >
            Próximo
          </CButton>
          <!-- <CButton
            v-if="index === 2"
            color="primary"
            @click="avancarEtapa"
            class="button-largura"
          >
            Próximo
          </CButton> -->
          <CButton v-if="index === 2"
            color="success"
            @click="finalizar"
            class="button-largura"
            :disabled="!hasProdutosSelecionados"
          >
            Confirmar
          </CButton>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    isColunaSelecionavel: {
      type: Boolean,
      required: true
    },
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    hasProdutosSelecionados: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentStep: {
      get() {
        return this.step;
      },
      set(value) {
        this.$emit("update:step", value);
      },
    },
  },
  methods: {
    avancarEtapa(data = null) {
      if (this.currentStep < this.steps.length) {
        this.currentStep += 1;
        this.$emit("etapa-avancada", { step: this.currentStep, data });
      }
    },
    voltarEtapa() {
      if (this.currentStep > 1) {
        this.currentStep = 1;
        this.$emit('voltar-etapa')
      }
    },
    finalizar() {
      this.$emit("finalizar");
    },
  },
};
</script>

<style scoped>
  .button-largura {
    width: 120px;
  }

  .step-actions {
    display: flex;
    justify-content: end;
    gap: 8px;
  }
</style>