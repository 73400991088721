<template>
  <div class="segmentos-container">
    <div class="segmentos-header">
      <div class="usa-restricao">
        <input
          id="usaRestricao"
          type="checkbox"
          :checked="usaRestricaoCompra === 1"
          @change="toggleCheckbox"
        />
        <label for="usaRestricao">Usar Restrições de Compra</label>
      </div>
      <div class="action-buttons">
        <CButton color="danger" @click="limpar()">Limpar</CButton>
        <CButton color="primary" @click="liberarTodos()">Liberar Todos</CButton>
      </div>
     
    </div>
    <div class="segmentos-list">
      <select
        id="segmentoSelect"
        v-model="segmentoSelecionado"
        class="form-control"
        :disabled="!usaRestricaoCompra"
        @change="handleSegmentoSelecionado"
      >
        <option value="" hidden>Selecione</option>
        <option
          v-for="segmento in this.segmentos"
          :key="segmento.coluna"
          :value="segmento.coluna"
        >
          {{ segmento.label }}
        </option>
      </select>
       <div>
        <input
          type="text"
          v-model="filter"
          placeholder="Filtrar dimensões"
          class="form-control search-input"
        />
      </div>
    </div>

    <div v-if="dimensoes.length > 0" class="dimensoes-tabela">
      <div class="table-container" v-if="segmentoSelecionado">
        <table class="table table-striped" >
          <thead>
            <tr>
              <th>ID</th>
              <th>Descrição</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dimensao in filteredDimensoes" :key="dimensao.id">
              <td>{{ dimensao.id }}</td>
              <td>{{ dimensao.desc }}</td>
              <td>
                <label class="c-switch c-switch-sm c-switch-3d c-switch-success">
                  <input 
                    type="checkbox"
                    class="c-switch-input"
                    name="checkCliente"
                    :checked="dimensoesRestritas.some(item => item.id_dimensao_bloqueada === dimensao.id)"
                    @change="toggleDimensao(dimensao.id, +segmentoSelecionado)"
                  />
                  <span class="c-switch-slider"></span>
                </label>
              </td>
            </tr>
            <tr v-if="filteredDimensoes.length === 0">
              <td colspan="3" class="text-center">Nenhuma dimensão encontrada</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Segmentos",
  props: {
    segmentos: {
      type: Array,
      required: true,
    },
    colaboradorId: {
      type: Number,
      required: true,
    },
    usaRestricaoCompra: {
      type: Number,
      required: false,
    },
    dimensoesRestritas: {
      type: Array,
      required: false,
      default: () => [],
    },
    limpaDimensoes: {
      type: Function,
      required: false
    },
    liberarDimensoes:{
      type: Function,
      required: false
    }
  },
  data() {
    return {
      filter: "",
      segmentoSelecionado: "",
      dimensoes: [],
    };
  },
  computed: {
    filteredSegmentos() {
      return this.segmentos.filter((segmento) =>
        segmento.label.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    filteredDimensoes() {
      if (!this.filter) {
        return this.dimensoes;
      }
      return this.dimensoes.filter(
        (dimensao) =>
          dimensao.desc.toLowerCase().includes(this.filter.toLowerCase()) ||
          dimensao.id.toString().includes(this.filter)
      );
    },
  },
  methods: {
    async liberarTodos(){
      const novosRestritos = await this.dimensoes.map((dimensao) => ({
        id_dimensao_bloqueada: dimensao.id,
        coluna_dimensao_bloqueada: this.segmentoSelecionado
      }));
      
      this.liberarDimensoes(novosRestritos);
    },
    limpar(){
      const dimensoesAtualizadas = this.dimensoesRestritas.filter(
        (dimensao) => dimensao.coluna_dimensao_bloqueada !== this.segmentoSelecionado
      );
      this.limpaDimensoes(dimensoesAtualizadas);
    },
    toggleCheckbox(event) {
      const novoValor = event.target.checked ? 1 : 0;
      this.$emit("usa-restricao", novoValor);
    },
    handleSegmentoSelecionado() {
      const findDimensoes = this.segmentos.find(
        (segmento) => segmento.coluna === this.segmentoSelecionado
      );
      this.dimensoes = findDimensoes ? findDimensoes.dimensoes : [];
      this.$emit("segmentoSelecionado", this.segmentoSelecionado);
    },
    toggleDimensao(dimensaoId, colunaDimensao) {
      const index = this.dimensoesRestritas.findIndex(
        (item) => item.id_dimensao_bloqueada === dimensaoId
      );

      if (index > -1) {
        this.dimensoesRestritas.splice(index, 1);
      } else {
        this.dimensoesRestritas.push({
          id_dimensao_bloqueada: dimensaoId,
          coluna_dimensao_bloqueada: colunaDimensao,
        });
      }
    },
  },
  mounted() {
    // Executa apenas uma vez ao montar o componente
    if (this.dimensoesRestritas) {
      this.dimensoes = this.dimensoesRestritas
    }
  },
};
</script>

<style scoped>
  .segmentos-container {
    padding: 1rem;
  }
  .segmentos-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .segmentos-list{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  .action-buttons{
    display: flex;
    gap: 1rem;
  }
  .search-input {
    width: 200px;
  }
  .table-container {
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table th,
  .table td {
    padding: 8px;
  }
  .button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .button-success {
    background-color: #28a745;
    color: white;
    border: none;
  }
  .button-error {
    background-color: #dc3545;
    color: white;
    border: none;
  }
  .usa-restricao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .usa-restricao label {
    vertical-align: middle;
    margin: 0;
  }
</style>
