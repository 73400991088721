<style scoped>
    .codemirror {
        font-size: 10px;
    }

    .query-tarefa .codemirror .CodeMirror {
        height: 600px;
    }

    .CodeMirror .readOnly {
        background-color: #cccccc;
    }
    /* BDTI custom */
    .bdti-alert {
    margin: 1% auto;
    padding: 1% 1%;
    border-radius: 3px;
    }

    .bdti-alert-danger {
    background: rgba(255, 0, 0, 0.714);
    color: white;
    }
</style>

<template>
    <modal name="tarefaEdit" height="auto" width="1024" :scrollable="true" :clickToClose="false">
        <CloseModal :close="close"/>
        <div class="modalbox query-tarefa">
            <div class="modalbox-content" v-if="!!tarefa">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab :title="descricao">
                        <div class="form">
                            <div class="bdti-alert bdti-alert-danger" v-if="error">{{error}}</div>
                            <div class="xrow">
                                <div class="col-1-2">
                                    <div class="xrow">
                                        <div class="col-1-2">
                                            <div class="form-group">
                                                <label for="">Tabela/View ERP</label>
                                                <input type="text" class="form-control" v-model="tarefa.tarefa"
                                                       disabled/>
                                            </div>
                                        </div>
                                        <div class="col-1-2">
                                            <div class="form-group">
                                                <label for="">Tabela/Tarefa Local</label>
                                                <input type="text" class="form-control" v-model="tarefa.tabela"
                                                       disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xrow">
                                        <div class="col-2-3">
                                            <div class="form-group">
                                                <label for="">{{tarefa ? tarefa.descricao : ''}}</label>
                                                <select class="form-control" v-model="tarefa.cron">
                                                    <option v-for="(label, cron) in crons" v-bind:value="cron">
                                                        {{ label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1-3">
                                            <div class="form-group">
                                                <label for="">Ativo?</label>
                                                <select class="form-control" v-model="tarefa.ativo">
                                                    <option value="1">Sim</option>
                                                    <option value="0">Não</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xrow">
                                        <div class="col-1-1">
                                            <div class="form-group">
                                                <label for="">Modo de sincronição</label>
                                                <select class="form-control" v-model="tarefa.modo">
                                                    <option v-for="(modo, tipo) in modos" :value="tipo">{{modo.label}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1-2" v-if="usaBancoExecucao">
                                          <div class="form-group">
                                            <label for="">Banco de execução</label>
                                            <select class="form-control" v-model="tarefa.banco">
                                              <option value="local">Local (Postgres Versotech)</option>
                                              <option value="erp">ERP</option>
                                            </select>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="xrow"
                                         v-show="tarefa.modo === 'NCR_LOTE' || tarefa.modo === 'NCR_LOTE_INDEX' || tarefa.modo === 'HASH_LOTE'">
                                        <div class="col-1-1">
                                            <div class="form-group">
                                                <label for="">Quantidade de registros por lote (default 10000)</label>
                                                <input type="text" class="form-control" v-model="tarefa.loteQtd"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xrow" v-show="tarefa.modo === 'NCR_LOTE_INDEX' || tarefa.modo === 'HASH_LOTE'">
                                        <div class="col-1-1">
                                            <div class="form-group">
                                                <label for="">Nome da coluna de índice (default ID)</label>
                                                <input type="text" class="form-control" v-model="tarefa.index"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xrow">
                                        <div class="col-1-2" v-if="!!modos[tarefa.modo] && modos[tarefa.modo].usaEliminaRegistros">
                                            <div class="form-group">
                                                <label for="">Remover registros inválidos?</label>
                                                <select class="form-control" v-model="tarefa.excluirInvalidos">
                                                    <option :value="1">Sim</option>
                                                    <option :value="0">Não</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1-2" v-if="!!modos[tarefa.modo] && modos[tarefa.modo].usaMargemMinimaRegistros">
                                            <div class="form-group" title="Os registros obtidos do erp devem ter no mínimo o percentual estipulado abaixo ao ser comparado com o número de registros já existentes no sistema">
                                                <label for="">Perc. mín. registros</label>
                                                <input type="number" class="form-control" v-model="tarefa.percentualMinimo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xrow">
                                        <div class="col-1-2" v-if="!!modos[tarefa.modo] && modos[tarefa.modo].usaManutencao">
                                            <div class="form-group">
                                                <label for="">Modo de manutenção</label>
                                                <select class="form-control" v-model="tarefa.manutencao">
                                                    <option value="">Nenhum</option>
                                                    <option value="SYNC">Limpar registros e sincronizar tabela</option>
                                                    <option value="REINDEX">Reindexar tabela</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1-2" v-if="!!modos[tarefa.modo] && modos[tarefa.modo].usaModoExecucao">
                                            <div class="form-group">
                                                <label for="">Modo de execução</label>
                                                <select class="form-control" v-model="tarefa.tipoExecucao">
                                                    <option value="DEFAULT">Padrão ( Select em view/tabela )</option>
                                                    <option value="SQL">Executar SQL customizado</option>
                                                </select>
                                            </div>
                                        </div>
                                      <div class="col-1-1" v-if="!!tarefa && !!modos[tarefa.modo]">
                                        <span><b>Funcionamento {{ modos[tarefa.modo].label }}</b></span>
                                        <br>
                                        <small>{{ modos[tarefa.modo].obs }}</small>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-1-2">
                                    <div class="form-group">
                                        <label for="">
                                          <span v-if="usaScript">
                                            Código a ser executado
                                          </span>
                                          <span v-else>
                                            SQL Customizado
                                            <small v-show="!usaQueryCustomizada">(Desabilitado)</small>
                                          </span>
                                        </label>
                                        <div class="codemirror">
                                            <codemirror
                                                v-if="usaQueryCustomizada || usaScript"
                                                v-model="tarefa.sql"
                                                :options="code_options"/>
                                            <codemirror
                                                v-else-if="!usaQueryCustomizada && usaQuery"
                                                :value="`SELECT * FROM ${tarefa.tarefa}`"
                                                :options="code_options"/>
                                            <codemirror
                                                v-else-if="!usaQuery"
                                                :options="code_options"
                                            />
                                        </div>
                                        <p>
                                            <small v-show="usaQuery && tarefa.modo === 'INCREMENTAL'">
                                                ATENÇÃO: para utilização de query no formato incremental, é necessário
                                                adicionar o parâmetro
                                                <strong>:P_DATAATUALIZACAO</strong>, este parâmetro será substituído
                                                pela data/hora de última sincronização,
                                                como no exemplo abaixo:
                                                <br>
                                                <strong>
                        <pre>
SELECT *
  FROM PEDIDO
 WHERE 1=1
   AND PEDIDO.DATAATUALIZACAO >= :P_DATAATUALIZACAO
                        </pre>
                                                </strong>
                                            </small>
                                            <small v-show="usaQuery && (tarefa.modo === 'NCR_LOTE' || tarefa.modo === 'NCR_LOTE_INDEX' || tarefa.modo === 'HASH_LOTE')">
                                                ATENÇÃO: para utilização de query no formato de lote paginado, é
                                                necessário adicionar os parâmetros
                                                <strong>:P_FROM</strong> e <strong>:P_TAKE</strong>, estes parâmetros
                                                serão substituídos respectivamente,
                                                pela página atual e a quantidade de registros que deseja obter, como no
                                                exemplo abaixo:
                                                <br>
                                                <strong>
                        <pre style="margin-bottom: 0">
SELECT *
  FROM PEDIDO
 WHERE 1=1
OFFSET :P_FROM
 LIMIT :P_TAKE
</pre>
                                                </strong>
                                                ou, caso seu banco de dados não aceite as condições de offset e limite,
                                                segue exemplo abaixo:
                                                <strong>
                      <pre style="margin-bottom: 0">
SELECT *
  FROM PEDIDO
 WHERE 1=1
   AND ROWNUM BETWEEN :P_FROM AND :P_FROM + :P_TAKE
                        </pre>
                                                </strong>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-grow-1">
                <button
                    title="Eliminar todos os registros da tabela"
                    class="button button-error align-start"
                    v-on:click="handleTruncate"
                >
                    Limpar registros
                </button>
            </div>
            <div>
                <button
                    :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                    v-on:click="handleSubmit"
                >
                    Atualizar
                </button>
                <button class="button" v-on:click="close">Fechar</button>
            </div>
        </div>
    </modal>
</template>
<script>
  import {Validator} from "simple-vue-validator";
  import CloseModal from "../../components/CloseModal";

  import {codemirror} from 'vue-codemirror'
  // theme css
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/monokai.css'
  import 'codemirror/theme/zenburn.css'
  // language js
  import 'codemirror/addon/selection/active-line.js'
  import 'codemirror/addon/display/autorefresh.js'
  import 'codemirror/mode/sql/sql.js'
  import 'codemirror/mode/php/php'
  // autoCloseTags
  import 'codemirror/addon/edit/closetag.js'

  export default {
    name: "tarefaEdit",
    props: [
      "crons",
      "update",
      "originalTarefa",
      "truncate",
      "close",
      "tarefa",
      "descricao",
      "modos"
    ],
    validators: {
      "tarefa.cron": (value) => Validator.value(value).required(),
      "tarefa.tarefa": (value) => Validator.value(value).required(),
    },
    components: {
      CloseModal,
      codemirror
    },
    data: function () {
      return {
        error: false,
      };
    },
    computed: {
      podeLimparTabela() {
        return this.tarefa.modo === 'NCR'
          || this.tarefa.modo === 'NCR_LOTE'
          || this.tarefa.modo === 'NCR_LOTE_INDEX'
          || this.tarefa.modo === 'INCREMENTAL'
          || this.tarefa.modo === 'COMPARATOR'
          || this.tarefa.modo === 'HASH_COMPARATOR'
          || this.tarefa.modo === 'HASH_LOTE'
      },
      usaQuery() {
        return this.tarefa.modo === 'NCR'
          || this.tarefa.modo === 'NCR_LOTE'
          || this.tarefa.modo === 'NCR_LOTE_INDEX'
          || this.tarefa.modo === 'INCREMENTAL'
          || this.tarefa.modo === 'COMPARATOR'
          || this.tarefa.modo === 'HASH_COMPARATOR'
          || this.tarefa.modo === 'HASH_LOTE'
      },
      usaBancoExecucao() {
        return !!(this.tarefa
            && this.modos[this.tarefa.modo]
            && this.modos[this.tarefa.modo].usaBancoExecucao);
      },
      usaScript() {
        return this.tarefa.modo === 'SQL_SCRIPT'
          || this.tarefa.modo === 'PHP_SCRIPT';
      },
      usaQueryCustomizada() {
        return (this.usaQuery && this.tarefa.tipoExecucao === 'SQL')
            || this.usaScript;
      },
      code_options() {
        let mode = 'text/x-sql';

        if (this.modos && this.tarefa && this.modos[this.tarefa.modo] && this.modos[this.tarefa.modo].tipoScript ) {
          mode = this.modos[this.tarefa.modo].tipoScript;
        }

        return {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: mode || 'text/x-sql',
          autoRefresh:true,
          lineWrapping: true,
          readOnly: this.usaQueryCustomizada ? false : 'nocursor',
          disabled: true,
          theme: this.usaQueryCustomizada ? 'monokai' : 'zenburn',
          showCursorWhenSelecting: true,
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        }
      }
    },
    watch: {
      tarefa: function () {
        this.error = false;
      },
    },
    methods: {
      handleTruncate() {
        this.truncate(this.tarefa);
      },
      handleSubmit() {
        let self = this;
        self.error = false;
        this.$validate().then((success) => {
          if (success) {
            let request = this.update(this.tarefa);
            request.then((json) => {
              this.close();
              this.$swal({
                title: 'Salvo com sucesso',
                text: json.message,
                showCancelButton: false,
                cancelButtonText: 'Fechar',
                showConfirmButton: true,
                icon: 'success',
              });
            });
            request.catch((error) => {
              self.error = error.message;
              this.$swal({
                title: 'Falha ao Salvar',
                text: error.message,
                showCancelButton: true,
                cancelButtonText: 'Fechar',
                showConfirmButton: false,
                icon: 'error',
              });
            });
          }
        });
      },
    },
  };
</script>
