<template>
  <modal
    name="modalImportar"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
    @before-open="onModalOpen"
  >
    <CloseModal :close="close" />

    <div class="modalbox">
      <div class="modalbox-header">
        <h3>{{ titulo }}</h3>
      </div>
      <div class="modalbox-content">
        <Stepper 
          :steps="['Seleção', 'Importar arquivo', 'Confirmação']"
          :step.sync="currentStep"
          :isColunaSelecionavel="isColunaSelecionavel"
          :hasProdutosSelecionados="hasProdutosSelecionados"
          @finalizar="handleFinalizar"
          @etapa-avancada="handleSelecionaColuna"
          @voltar-etapa="handleVoltar"
        >
          <template v-slot:step.1 class="lista-produtos-adicionados">
            <v-card title="Seleção" flat>
              <v-card-text>
                 <div class="row">
                  <div class="col-12">
                    <vue-dropzone 
                      :useCustomSlot="true"
                      ref="dropzone"
                      id="dropzone"
                      @vdropzone-success="uploadProdutosdeArquivo"
                      @vdropzone-removed-file="limpaArquivosSelecionados"
                      :options="dropzoneOptions"
                    >
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">
                          Arraste e solte para importar de um arquivo!
                        </h3>
                        <div class="dropzone-custom-subtitle">
                          ...ou clique para selecionar um arquivo do seu computador
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>

          <template v-slot:step.2>
            <div class="lista-produtos-adicionados">
              <div v-if="arquivoUpload">
                <TabelaImportarProdutos
                :arquivoInfo="arquivoUpload"
                @column-selected="handleColumnSelection"
                />
              </div>
              <div v-else>
                Nenhum arquivo encontrado
              </div>
            </div>
          </template>
          
          <template v-slot:step.3 class="lista-produtos-adicionados"> 
            <div class="lista-produtos-adicionados">
              <div v-if="!listaSelectedProdutos.itens || listaSelectedProdutos.itens.length === 0" class="text-center p-4">
                <span>Nenhum produto encontrado</span>
              </div>
              <div v-else="listaSelectedProdutos.itens" v-for="produto in listaSelectedProdutos.itens" :key="produto.id">
                <CCard class="card">
                  <CCardTitle class="text">
                    <p>COD</p>
                    <label>{{produto.idExibicao}}</label>
                  </CCardTitle>
                  <img class="image" :src="cdnUrl(produto.idExibicao)" :alt="produto.descComp"/>
                  <CCardBody class="cardBody">
                    <CCardText class="text">
                      <p>Descrição</p>
                      <label>  {{ produto.descComp }}</label>
                    </CCardText>
                  </CCardBody>
                </CCard>
              </div>
            </div>
          </template>
        </Stepper>
      </div>
    </div>
  </modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import CloseModal from "../../../components/CloseModal.vue";
import setup from "../../../config";
import Stepper from "../../../components/steppers/Stepper.vue";
import { post } from "../../../helpers/apiRequest";
import TabelaImportarProdutos from "./TabelaImportarProdutos.vue";
import { cdnUrl, cdnUrlProduto } from "../../../helpers/url";
import { makeHash } from "../../../helpers/utils";

const dropzoneDictionary = {
  dictDefaultMessage: "Arraste um arquivo .xls(x) ou clique para importar produtos",
  dictFallbackMessage: "Seu navegador não suporta upload o recurso de arrastar e soltar",
  dictFileTooBig: "O arquivo excedeu o tamanho máximo permitido (5MB)",
  dictInvalidFileType: "Arquivo no formato inválido",
  dictResponseError: "Erro ao processar solicitação, tente novamente!",
  dictCancelUpload: "Cancelar",
  dictCancelUploadConfirmation: "Tem certeza?",
};

export default {
  name: "ModalImportar",
  components: {
    CloseModal,
    vueDropzone: vue2Dropzone,
    Stepper,
    TabelaImportarProdutos

  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    titulo: {
      type: String,
      default: "Importar Produtos",
    },
  },
  data() {
    return {
      currentStep: 1,
      arquivoUpload: null,
      selectedColumn: null,
      listaSelectedProdutos: [],
      currentHash: makeHash(),
      isColunaSelecionavel: false,
      dropzoneOptions: {
        url: `${setup.api}/admin/cupons/importar-produtos`,
        acceptedFiles: ".xls, .xlsx",
        maxFilesize: 1,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        paramName: "produtos",
        ...dropzoneDictionary,
      },
    };
  },
  watch: {
    currentStep(newValue){
      if(newValue === 1){
        this.limpaArquivosSelecionados()
      }
    }
  },
  computed: {
    hasProdutosSelecionados() {
      return this.listaSelectedProdutos?.itens?.length > 0;
    },
  },
  methods: {
    handleVoltar(){
      this.listaSelectedProdutos = [];
      this.isColunaSelecionavel = false;
      this.selectedColumn = null;
    },
    handleColumnSelection(selectedColumn) {
      this.selectedColumn = selectedColumn;
      this.isColunaSelecionavel = true;
    },
    onModalOpen() {
      this.arquivoUpload = null;
      this.currentStep = 1;
    },
    limpaArquivosSelecionados() {
      this.$refs.dropzone.removeAllFiles();
    },
    async uploadProdutosdeArquivo(file) {
      try {
        const formData = new FormData();
        formData.append("arquivo", file);
  
        const data = await post('/admin/restricoes/importar-arquivo', formData, {isFile: true});
  
        this.arquivoUpload = data
  
        this.currentStep = 2;
      } catch (error) {
        console.error(error);
      }
    },
    async handleSelecionaColuna(){
      try {
        const produtos = this.arquivoUpload.rows.map((produto) => ({
         id: produto[this.selectedColumn]
       }));
       
       const response = await post('/admin/restricoes/confirmar-importacao', { 
         produtos: produtos 
       });
 
       this.listaSelectedProdutos = response;
      } catch (error) {
        console.error(error);
      }
    },
    async handleFinalizar() {
      
      const produtos = await this.listaSelectedProdutos.itens.map((produto) => ({
        value: +produto.id,
        label: produto.descComp,
        idImagem: produto.idImagem
      }));
      
      this.$emit("produtos-importados", produtos);
      
      this.currentStep = 1;
      this.arquivoUpload = null;
      this.selectedColumn = null;
      this.listaSelectedProdutos = [];
      this.close();
    },
    cdnUrl(id) {
      return cdnUrlProduto(id, this.currentHash);
    },
  },
};
</script>

<style scoped>

  .modalbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 1rem;
  }

  .modalbox-content {
    padding: 1rem;
  }

  .dropzone-custom-content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
  }

  .dropzone-custom-title {
    color: #00b782;
    font-size: 1.5rem;
    margin: 0 0 0.6rem;
    background-color: initial;
  }

  .dropzone-custom-subtitle {
    color: #314b5f;
  }

   .card{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 0.5rem;
    padding-inline: 2rem;
  }

  .text p {
    font-weight: bold;
  }
  .cardBody {
    display: flex;
    justify-content: space-between;
  }
  .image {
    width: 120px;
    height: 120px;
  }

  .lista-produtos-adicionados{
    max-height: 420px;
    overflow-y: scroll;
  }
</style>
